<template>
	<el-dialog style="z-index: 999998" :title="'进度表格数据导入'" :close-on-click-modal="false" :visible.sync="visible"
		width="600px">
		<el-form ref="dataForm" :model="dataForm" inline label-width="120px" label-position="left">
			<el-row>
				<el-col :span="24">
					<el-upload class="upload-demo" ref="uploadImg" :action="action" :http-request="uploadImg"
						accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
						style="margin-top: 20px;">
						<el-button v-preventReClick size="small" type="primary">点击上传</el-button>
					</el-upload>
				</el-col>
			</el-row>
		</el-form>
	</el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
	data() {
		return {
			visible: true,
			action: '',
			form: {},
			dataForm: {
				workNo: '',
				subjectNo: '',
				major: '',
				progressNo: '',
			},
			dataRule: {
			},
			subjectList: [],
		};
	},
	components: {

	},
	mounted() {
	},
	methods: {
		uploadImg(param) {
			//创建loading对象开始遮罩
			const rLoading = this.openLoading();
			const formData = new FormData();
			formData.append('file', param.file);
			formData.append('workNo', $common.getItem("workNo"))
			formData.append('subjectNo', this.dataForm.subjectNo)
			formData.append('major', this.dataForm.major)
			formData.append('progressNo', this.dataForm.progressNo)
			this.$refs.uploadImg.clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/business/projectsubprogressrecord/uploadFileList",
				formData, {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			},
			).then(res => {
				rLoading.close();
				res = res.data
				if (res.resultCode === 200) {
					this.$message({
						message: "操作成功",
						type: "success",
						duration: 1500,
						onClose: () => {
							this.visible = false;
							this.$parent.getDataList();
						}
					});
				}
			})
		},
		init(subjectNo, major, progressNo) {
			this.dataForm = {
				workNo: $common.getItem("workNo"),
				subjectNo: subjectNo,
				major: major,
				progressNo: progressNo,
			}
			this.$nextTick(() => {
				this.visible = true;
			})
		},
	}
};
</script>

<style lang="scss" scoped="scoped">
.selItemInput.descAreaLg {
	width: 718px !important;
}

.addImgBox {
	width: 136px;
	height: 136px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
}

.fondstyle {
	color: #3f649f;
	font-size: 15px;
}
</style>
